import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	account: css`
		position: relative;
		margin-right: 60px;
		width: 250px;
	`,
	sidebarAccount: css`
		padding: 20px;
		margin: 0;
		border-top: 1px solid ${Theme.colors.black(0.2)};
	`,
	reduced: css`
		width: fit-content;
		padding: 5px;
	`,
	sidebarBlack: css`
		color: ${Theme.colors.black()};
	`,
	sidebarGrey: css`
		color: ${Theme.colors.black(0.2)};
	`,

	accountBlock: css`
		cursor: pointer;
		border-radius: 12px;
		padding: 8px;
		transition: box-shadow 0.2s ease-in-out;
		&:hover {
			box-shadow: 0px 0px 0px 1px ${Theme.colors.grey3};
		}
	`,
	sidebarAccountBlock: css`
		&:hover {
			box-shadow: 0px 0px 0px 1px ${Theme.colors.black(0.2)};
		}
	`,

	avatar: css`
		border-radius: 8px;
		overflow: hidden;
	`,

	username: css`
		color: ${Theme.colors.white()};
		font-weight: bold;
	`,
	email: css`
		color: ${Theme.colors.grey3};
		font-weight: normal;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	`,

	popover: css`
		box-shadow: 0 0 4px ${Theme.colors.black(0.5)};
		background-color: ${Theme.colors.grey1};
		padding: 18px 25px;
		border-radius: 10px;
		transform: translateX(-50%);
		position: absolute;
		&:before {
			position: absolute;
			width: 10px;
			height: 10px;
			content: '';
			background-color: ${Theme.colors.grey1};
			transform: translateX(-50%) rotate(45deg);
		}
	`,
	headerPopover: css`
		top: 62px;
		left: 50%;
		&:before {
			left: 50%;
			top: -5px;
		}
	`,

	sidebarPopover: css`
		right: -335px;
		bottom: 10px;
		&:before {
			bottom: 30px;
			left: 0px;
		}
	`,
	reducedPopover: css`
		transform: translateX(-40%);
		&:before {
			bottom: 20px;
		}
	`,

	popoverLabel: css`
		color: ${Theme.colors.white()};
		font-weight: bold;
		white-space: nowrap;
	`,

	popoverEntry: css`
		&:hover {
			opacity: 0.8;
		}
		cursor: pointer;
	`
}
