import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import { Flex, Box } from 'rebass'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Style from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Placeholder from '@components/Micro/Image'
import Link from 'next/link'
import { useAuth } from '@helpers/contexts/authUserContext'
import { useApi } from '@helpers/contexts/apiContext'
import Utils from '@utils/index'
import { Channel } from 'types/channel'
import { useChannelsContext } from '@helpers/contexts/channelsContext'

interface Props {
	storeUser?: any
	innerRef: any
	position: 'header' | 'sidebar'
	currentChannel?: Channel
	isReduced?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const AccountBlock: React.FC<Props> = ({ innerRef, position = 'header', currentChannel, isReduced = false }) => {
	const [popoverIsOpened, setPopoverIsOpened] = useState(false)
	const [role, setRole] = useState(null)
	const { signOut, authUser } = useAuth()
	const { userAccesses } = useApi()
	const router = useRouter()
	const { isAdmin } = useChannelsContext()

	const windowClickHandler = (e) => {
		if (innerRef.current) {
			if (!innerRef.current.contains(e.target)) {
				setPopoverIsOpened(false)
			}
		}
	}

	const logout = () => {
		signOut()
			.then(() => {
				window.location.href = '/login' // Force reload to reload component
			})
			.catch((error) => {
				console.log(error.code, error.message)
			})
	}

	const routeChangeHandler = () => {
		setPopoverIsOpened(false)
	}

	useEffect(() => {
		window.addEventListener('click', windowClickHandler)
		router.events.on('routeChangeStart', routeChangeHandler)
		return () => {
			window.removeEventListener('click', windowClickHandler)
			router.events.off('routeChangeStart', routeChangeHandler)
		}
	}, [userAccesses])

	useEffect(() => {
		getUserRole()
	}, [userAccesses])

	const isOnSidebar = position === 'sidebar'

	const getUserRole = () => {
		if (isOnSidebar && userAccesses) {
			if (userAccesses.find((e) => e.role === 'superadmin')) return setRole('superadmin')
			const access = userAccesses.find((access) => access.channelId === currentChannel.uuid)?.role
			if (access) setRole(access)
		}
	}

	const profilePicture = authUser.photoURL || 'medias/default/avatar-100x100.jpg'

	return (
		<Box
			ref={innerRef}
			css={[
				Style.account,
				position === 'sidebar' ? Style.sidebarAccount : null,
				isReduced ? Style.reduced : null
			]}
		>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				css={[Style.accountBlock, isOnSidebar ? Style.sidebarAccountBlock : null]}
				onClick={() => {
					setPopoverIsOpened(!popoverIsOpened)
				}}
			>
				<Flex alignItems="center">
					<Box width={50} height="100%" css={Style.avatar} mr={10}>
						<Placeholder
							sizes={[[30, 30]]}
							media={{ url: profilePicture }}
							color={Theme.colors.white(0.75)}
							fit="cover"
						/>
					</Box>
					{!isReduced && (
						<Box width={1} alignItems="center">
							<Box
								width={1}
								css={[Style.username, isOnSidebar ? Style.sidebarBlack : null]}
								fontSize={'14px'}
								mt={'2px'}
							>
								{authUser.displayName}
							</Box>
							<Box css={Style.email} fontSize={'12px'} mt={'4px'}>
								{isOnSidebar && role ? Utils.capitalize(role) : authUser.email}
							</Box>
						</Box>
					)}
				</Flex>
				<Box width={[14]} height={[9]}>
					<Icon
						name={'chevron'}
						r={90}
						width="100%"
						height="100%"
						fill={isOnSidebar ? Theme.colors.black() : Theme.colors.white()}
					/>
				</Box>
			</Flex>
			{popoverIsOpened && (
				<Box
					css={[
						Style.popover,
						isOnSidebar ? Style.sidebarPopover : Style.headerPopover,
						isReduced ? Style.reducedPopover : null
					]}
				>
					<Link href="/account">
						<Flex p={10} width={1} as="a" css={Style.popoverEntry}>
							<Box width={14} height={14}>
								<Icon name={'user'} width="100%" height="100%" fill={'#fff'} />
							</Box>
							<Box css={Style.popoverLabel} fontSize={'16px'} ml={15}>
								My account
							</Box>
						</Flex>
					</Link>
					{isOnSidebar && isAdmin && (
						<Link href={`/${currentChannel.uuid}/edit`}>
							<Flex p={10} width={1} as="a" css={Style.popoverEntry}>
								<Box width={14} height={14}>
									<Icon name={'settings'} width="100%" height="100%" fill={'#fff'} />
								</Box>
								<Box css={Style.popoverLabel} fontSize={'16px'} ml={15}>
									Channel settings
								</Box>
							</Flex>
						</Link>
					)}
					<Flex p={10} width={1} css={Style.popoverEntry} onClick={logout}>
						<Box width={14} height={14}>
							<Icon name={'logout'} width="100%" height="100%" fill={'#fff'} />
						</Box>
						<Box css={Style.popoverLabel} fontSize={'16px'} ml={15}>
							Log out
						</Box>
					</Flex>
				</Box>
			)}
		</Box>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBlock)
